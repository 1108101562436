import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, memo } from 'react'
import cn from '~/utils/merge-classnames'
import type { ButtonHTMLAttributes, ReactNode, SVGProps } from 'react'

// TODO: The loading variant is not working correctly: upon hover or click, the underlying text is shown
const buttonVariants = cva(
  [
    'relative inline-flex w-fit items-center justify-center whitespace-nowrap rounded font-bold',
    'focus:shadow-none focus:outline focus:outline-2 focus:outline-offset-[3px] focus:outline-[#3376CD]',
    'transition-[opacity,color,background-color,box-shadow,text-decoration-color,fill,stroke] duration-100 ease-in-out'
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-brand-primary-bg text-brand-darkest shadow-subtle ring-1 ring-brand-primary-accent',
          'hover:bg-brand-primary-accent',
          'active:bg-brand-primary-bg/50 active:shadow-none',
          'disabled:cursor-not-allowed disabled:opacity-50'
        ],

        secondary: [
          'bg-brand-darkest text-white shadow-subtle ring-1 ring-transparent',
          'focus:outline-offset-2',
          'hover:bg-brand-darkest/80',
          'active:bg-brand-medium active:shadow-none',
          'disabled:cursor-not-allowed disabled:opacity-50'
        ],

        outline: [
          'bg-white text-brand-darkest shadow-subtle ring-1 ring-brand-light',
          'hover:ring-brand-medium',
          'active:bg-brand-light active:shadow-none active:ring-brand-medium',
          'disabled:cursor-not-allowed disabled:opacity-60'
        ],

        destructive: [
          'bg-white text-callout-red-90 ring-callout-red-30',
          'hover:shadow-subtle hover:ring-1 hover:ring-callout-red-90',
          'active:bg-callout-red-30/20 active:shadow-none',
          'disabled:cursor-not-allowed disabled:opacity-50'
        ],

        ghost: [
          'bg-white text-brand-darkest shadow-none ring-1 ring-transparent',
          'hover:ring-brand-medium',
          'active:bg-brand-light',
          'disabled:cursor-not-allowed disabled:opacity-60'
        ],

        link: [
          'bg-white text-brand-darkest underline-offset-4 ring-1 ring-transparent',
          'hover:underline',
          'active:bg-brand-light',
          'disabled:cursor-not-allowed disabled:opacity-60'
        ]
      },

      size: {
        sm: 'gap-x-1 px-2 py-0 text-sm/6',
        md: 'gap-x-1.5 px-3 py-1 text-sm/6',
        lg: 'gap-x-2 px-4 py-2 text-base/6',
        xl: 'gap-x-2 px-4 py-3 text-base/6'
      },

      loading: {
        true: '!cursor-progress !opacity-60'
      }
    },

    compoundVariants: [
      {
        variant: ['primary'],
        loading: true,
        className: 'fill-white/80'
      },
      {
        variant: ['outline', 'ghost', 'link'],
        loading: true,
        className: 'fill-brand-darkest text-brand-primary'
      }
    ],

    defaultVariants: {
      variant: 'primary',
      size: 'md'
    }
  }
)

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loading, disabled, children, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp className={cn(buttonVariants({ variant, size, loading }), className)} ref={ref} {...props} disabled={!!disabled || !!loading}>
        <Slottable>{children}</Slottable>

        {loading && (
          <div className="absolute inset-0 flex items-center justify-center rounded-[inherit] bg-[inherit]">{loadingSVGs[size ?? 'md']}</div>
        )}
      </Comp>
    )
  }
)

Button.displayName = 'Button'

export { Button }

const LoadingSVG = memo((props: SVGProps<SVGSVGElement>) => (
  <div role="status">
    <svg className="animate-spin fill-[currentFill] text-[currentColor]" {...props} xmlns="http://www.w3.org/2000/svg">
      <path fill="currentFill" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
      <path
        fill="currentColor"
        d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
      />
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
))

const loadingSVGs: Record<NonNullable<ButtonProps['size']>, ReactNode> = {
  sm: <LoadingSVG width="14" height="14" viewBox="0 0 24 24" />,
  md: <LoadingSVG width="20" height="20" viewBox="0 0 24 24" />,
  lg: <LoadingSVG width="24" height="24" viewBox="0 0 24 24" />,
  xl: <LoadingSVG width="24" height="24" viewBox="0 0 24 24" />
}
